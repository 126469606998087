












































import Vue from "vue";

export default Vue.extend({
  props: {
    candidate: {
      type: String,
    },
    inputName: {
      type: String,
    },
  },
  data: () => ({
    show: false,
    listOption: [
      {
        name: "歩行者",
        option: ["歩行者", "歩行者停止", "歩行者よし", "歩行者なし"],
      },
      {
        name: "自転車",
        option: ["自転車", "自転車停止", "自転車よし"],
      },
      {
        name: "2輪車",
        option: ["2輪車", "2輪車停止", "2輪車よし"],
      },
      {
        name: "車",
        option: [
          "車",
          "車停止",
          "車よし",
          "対向車",
          "対向車停止",
          "対向車よし",
          "対向車なし",
          "駐車車両",
          "駐車車両停止",
          "駐車車両注意",
          "駐車車両よし",
          "後続車",
          "後続車あり",
          "後続車なし",
          "先行車",
          "先行車停止",
          "先行車よし",
        ],
      },
      {
        name: "状況や場所",
        option: [
          "信号",
          "信号停止",
          "信号注意",
          "信号よし",
          "交差点",
          "交差点停止",
          "交差点よし",
          "飛び出し",
          "飛び出し停止",
          "飛び出しよし",
          "カーブミラー",
          "カーブミラー停止",
          "カーブミラーよし",
          "カーブミラーなし",
          "横断歩道",
          "横断歩道停止",
          "横断歩道よし",
          "横断歩道なし",
          "歩道",
          "歩道停止",
          "歩道よし",
          "止まれ",
          "止まれ停止",
          "止まれよし",
          "頂上付近",
          "頂上付近停止",
          "頂上付近注意",
          "頂上付近よし",
          "巻き込み",
          "巻き込み停止",
          "巻き込みよし",
          "右後方",
          "右後方停止",
          "右後方よし",
          "左後方",
          "左後方停止",
          "左後方よし",
          "周囲",
          "周囲停止",
          "周囲よし",
          "発進確認",
          "発進確認よし",
        ],
      },
      {
        name: "駐車",
        option: [
          "周囲よし ",
          "後方よし",
          "駐車スペースよし",
          "右後方よし ",
          "停止",
        ],
      }
    ],
    checkboxList: [],
    radioGroup: "危険対象がコメント出来ていませんでした。",
  }),
  watch: {
    checkboxList(val) {
      this.radioGroup = val.join("、");
    },
  },
  created() {
    if (!this.candidate) return;

    this.radioGroup = this.candidate;
  },
  methods: {
    submit() {
      this.show = !this.show;
      this.$emit("event", this.radioGroup, this.inputName);
    },
  },
});
