



































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Result from '@/components/commentaryAnalysis/Result.vue';
import Snackbar from '@/components/ui/Snackbar.vue';
import ReportDialog from '@/components/commentaryAnalysis/report/ReportDialog.vue';

export default Vue.extend({
  components: {
    Result,
    Snackbar,
    ReportDialog,
  },
  data: () => ({
    pid: '',
  }),
  computed: {
    ...mapGetters('CommentaryAnalysisModule', ['commentaryAnalysisResult', 'commentaryAnalysisResultHttpStatus']),
  },
  methods: {
    ...mapActions('CommentaryAnalysisModule', ['getCommentaryAnalysisResult']),
    ...mapActions('CommentaryAnalysisHistoriesModule', [
      'getCommentaryAnalysisHistory',
    ]),
    refs(): any {
      return this.$refs;
    },
  },
  mounted() {
    if (!this.$route.query.pid) {
      this.refs().snackbarRef.showSnackbar({
        color: 'error',
        text: '受講IDがありません。',
      });
      return;
    }
    this.pid = this.$route.query.pid as string;
    this.getCommentaryAnalysisResult(this.pid).then(() => {
      if (!this.commentaryAnalysisResult) {
        let text = `[${this.commentaryAnalysisResultHttpStatus}] `
        if (this.commentaryAnalysisResultHttpStatus === 500) {
          text += '解析結果がありません。新しいコメンタリー映像解析を開始してください。'
        } else if (this.commentaryAnalysisResultHttpStatus === 403) {
          text += '認証エラーです。社内ネットワークをご確認ください。'
        } else {
          text += 'サーバーが混みあっています。しばらくしてからもう一度確認してください。'
        }
        this.refs().snackbarRef.showSnackbar({
          color: 'error',
          text: text,
        });
        setTimeout(() => {
          window.close();
        }, 10000)
      }
    });
    this.getCommentaryAnalysisHistory({
      pid: this.pid,
    });
  },
});
