




















































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import ReportDialogImageItem from '@/components/commentaryAnalysis/report/ReportDialogImageItem.vue';
import CandidatesOfGeneralReviewDialog from '@/components/commentaryAnalysis/report/CandidatesOfGeneralReviewDialog.vue';
import GeneralReviewDialog from '@/components/commentaryAnalysis/report/GeneralReviewDialog.vue';
import Commentaries from "@/types/commentary";

export default Vue.extend({
  components: {
    ReportDialogImageItem,
    CandidatesOfGeneralReviewDialog,
    GeneralReviewDialog,
  },
  props: {
    pid: {
      type: String,
      required: true,
    },
  },

  data: function() {
    return {
      dialog: false,
      radioGroup: '指導ポイント',
      rules: [
        (v: any) =>
          (v || '').length <= 200 ||
          'Description must be 200 characters or less',
      ],
      candidate: '',
    };
  },
  computed: {
    ...mapGetters('CommentaryAnalysisModule', [
      'commentaryAnalysisResult',
      'commentaryAnalysisResultAggregate',
    ]),
    ...mapGetters('CommentaryAnalysisHistoriesModule', [
      'commentaryAnalysisHistories',
    ]),
  },
  created() {
    const kindLabelMap: any = {
      car: '車',
      person: '歩行者',
      bicycle: '自転車',
      motorcycle: '二輪車',
      traffic_light: '信号機',
    };
    const dessert = this.calculateDessert();
    let ok = ''
    let ng = ''
    for (const kind in dessert) {
      if (kindLabelMap[kind]) {
        if (dessert[kind] > 50) {
          ok += kindLabelMap[kind] + ' ';
        } else {
          ng += kindLabelMap[kind] + ' ';
        }
      }
    }
    if (ok !== '') {
      this.candidate += ok + 'に対してのコメンタリーはよく出来ていました。';
    }
    if (ng !== '') {
      this.candidate += ng + 'に対してのコメンタリーが少ないようです。';
    }
  },
  methods: {
    choseCandidate(candidate: string) {
      this.candidate += candidate;
    },
    changeTextArea(content: string) {
      this.candidate = content;
    },
    handlekeydown(e: any) {
      if (
        e.target.value.length >= 200 &&
        ![8, 37, 38, 39, 40, 46].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    },
    calculateDessert() {
      const desserts: any[] = [{
        person: 1,
        motorcycle: 1,
        bicycle: 1,
        car: 1,
        traffic_light: 1,
      }, {
        person: 0,
        motorcycle: 0,
        bicycle: 0,
        car: 0,
        traffic_light: 0
      }, {
        person: 0,
        motorcycle: 0,
        bicycle: 0,
        car: 0,
        traffic_light: 0,
      }];
      type KindOccurrence = {
        kind: string;
      };
      const total_occurrence: KindOccurrence[] = [];
      const history = this.commentaryAnalysisHistories[0];
      const attentionTarget = [
        'car',
        'person',
        'bicycle',
        'motorcycle',
        'truck',
        'bus',
        'traffic_light',
      ];
      this.commentaryAnalysisResult.commentaries.forEach(
          (comment: Commentaries) => {
            if (!attentionTarget.includes(comment.kind)) return;
            if (
                history.imageDisplayConditions.some((val: any) => {
                  return (
                      !val.isShow &&
                      val.time === comment.time &&
                      val.kind === comment.kind &&
                      !comment.commented
                  );
                })
            ) return;

            let count_commented = 0
            if (total_occurrence.some((val: KindOccurrence) => val.kind === comment.kind))
            {
              total_occurrence.forEach((k: KindOccurrence) => {
                if (k.kind === comment.kind) {
                  desserts[1][comment.kind]++;
                  if (comment.commented) count_commented++;
                  desserts[2][comment.kind] += count_commented
                }
              });
            } else {
              const a: KindOccurrence = { kind: comment.kind};
              desserts[1][comment.kind] = 1;
              if (comment.commented) count_commented++;
              desserts[2][comment.kind] = count_commented;
              total_occurrence.push(a);
            }
          }
      );

      attentionTarget.forEach((kind: string) => {
        if (desserts[2][kind] === 0) {
          desserts[0][kind] = 0;
        } else {
          desserts[0][kind] = Math.round((desserts[2][kind] / desserts[1][kind]) * 100);
        }
      });
      return desserts[0];
    },
  },
  mounted() {
    this.$store.dispatch('CommentaryAnalysisModule/initGuidancePointComment');
  },
});
