<template>
  <div class="box-item-review">
    <div v-if="isTitle" class="category">
      <slot name="title"></slot>
    </div>
    <div v-if="isBody" class="body" :class="isTitle ? '' : 'border-top'">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isTitle: {
      type: Boolean,
      default: true,
    },
    isBody: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .category {
    background-color: #86e3fd !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
.box-item-review {
  margin-bottom: 10px;
  width: 100%;
  .category {
    font-size: 1rem;
    font-weight: bold;
    padding-left: 10px;
    border: 1px solid #000;
    background-color: #86e3fd;
  }
  .body {
    font-size: 1rem;
    padding: 5px;
    border: 1px solid #000;
    border-top: none;
    word-break: break-word;
    min-height: 70px;
    &.border-top {
      border-top: 1px solid #000;
    }
  }
}
</style>
