











































import Vue from 'vue';

export default Vue.extend({
  props: {
    candidate: {
      type: String,
    },
    inputName: {
      type: String,
    },
  },
  data: () => ({
    show: false,
    radioGroup: '危険対象がコメント出来ていませんでした。',
  }),
  created() {
    if (!this.candidate) return;

    this.radioGroup = this.candidate;
  },
  methods: {
    submit() {
      this.show = !this.show;
      this.$emit('event', this.radioGroup, this.inputName);
    },
  },
});
