





































































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import BoxItemReview from '@/components/commentaryAnalysis/report/BoxItemReview.vue';
import RadarChart from '@/components/commentaryAnalysis/report/RadarChart.vue';
import Commentaries from '@/types/commentary';
import ImageDisplayCondition from '@/types/commentaryAnalysisHistory';

export default Vue.extend({
  props: ['generalProps', 'type'],
  components: {
    RadarChart,
    BoxItemReview
  },
  data: () => ({
    show: false,
    kindLabelMap: {
      car: '車',
      person: '歩行者',
      bicycle: '自転車',
      motorcycle: '二輪車',
      truck: 'トラック',
      bus: 'バス',
      traffic_light: '信号機',
      check: '呼称',
      prefix_check: '呼称 前',
      suffix_check: '呼称 後',
    },
    headers: [
      { text: '注意対象', value: 'name' },
      { text: '歩行者', value: 'person' },
      { text: '自転車', value: 'bicycle' },
      // { text: 'バス', value: 'bus' },
      // { text: 'トラック', value: 'truck' },
      { text: '二輪車', value: 'motorcycle' },
      { text: '車', value: 'car' },
      { text: '信号機', value: 'traffic_light' },
    ],
    desserts: [
      {
        name: '危険対象との一致',
        person: 1,
        motorcycle: 1,
        bus: 1,
        truck: 1,
        bicycle: 1,
        car: 1,
        traffic_light: 1,
      } as any,
      {
        name: '危険対象',
        person: 0,
        motorcycle: 0,
        bus: 0,
        truck: 0,
        bicycle: 0,
        car: 0,
        traffic_light: 0,
      } as any,
      {
        name: 'コメンタリー',
        person: 0,
        motorcycle: 0,
        bus: 0,
        truck: 0,
        bicycle: 0,
        car: 0,
        traffic_light: 0,
      } as any,
    ],
  }),
  computed: {
    ...mapGetters('CommentaryAnalysisModule', [
      'commentaryAnalysisResult',
      'commentaryAnalysisResultAggregate',
      'commentaryReview',
    ]),
    ...mapGetters('CommentaryAnalysisHistoriesModule', [
      'commentaryAnalysisHistories',
    ]),
    radarChartData() {
      type KindOccurrence = {
        kind: string;
      };
      const total_occurrence: KindOccurrence[] = [];
      const history = this.commentaryAnalysisHistories[0];
      const attentionTarget = [
        'car',
        'person',
        'bicycle',
        'motorcycle',
        'truck',
        'bus',
        'traffic_light',
      ];

      this.commentaryAnalysisResult.commentaries.forEach(
        (comment: Commentaries) => {
          if (!attentionTarget.includes(comment.kind)) return;

          if (
            history.imageDisplayConditions.some((val: any) => {
              return (
                !val.isShow &&
                val.time === comment.time &&
                val.kind === comment.kind &&
                !comment.commented
              );
            })
          ) return;

          let count_commented = 0
          if (total_occurrence.some((val: KindOccurrence) => val.kind === comment.kind))
          {
            total_occurrence.forEach((k: KindOccurrence) => {
              if (k.kind === comment.kind) {
                this.desserts[1][comment.kind]++;
                if (comment.commented) count_commented++;
                this.desserts[2][comment.kind] += count_commented
              }
            });
          } else {
            const a: KindOccurrence = { kind: comment.kind};
            this.desserts[1][comment.kind] = 1;
            if (comment.commented) count_commented++;
            this.desserts[2][comment.kind] = count_commented;
            total_occurrence.push(a);
          }
        }
      );

      const labels: Array<string> = [];
      const data_chart: Array<number> = [];
      this.headers.forEach((element: any) => {
        if (element.text !== '注意対象') {
          labels.push(element.text);
          if (this.desserts[2][element.value] == 0) {
            data_chart.push(0);
            this.desserts[0][element.value] = 0;
          } else {
            data_chart.push(
              (this.desserts[2][element.value] /
                this.desserts[1][element.value]) *
                100
            );
            this.desserts[0][element.value] = Math.round(
              (this.desserts[2][element.value] /
                this.desserts[1][element.value]) *
              100);
          }
        }
      });

      return {
        labels: labels,
        datasets: [
          {
            label: '注意対象',
            backgroundColor: 'rgb(54, 162, 235, 0.75)',
            data: data_chart,
          },
        ],
      };
    },
    formatDateTime() {
      let date = new Date();
      let lang = 'en',
        dayOfWeekArray = ['日', '月', '火', '水', '木', '金', '土'],
        year = date.toLocaleString(lang, { year: 'numeric' }),
        month = date.toLocaleString(lang, { month: 'numeric' }),
        day = date.toLocaleString(lang, { day: 'numeric' });

      let dayOfWeek = date.getDay();

      return `発行日： ${year}年 ${month}月 ${day}日 (${dayOfWeekArray[dayOfWeek]})`;
    },
  },
  methods: {
    formatRecordedDate(date: string) {
      return date ? DateTime.fromISO(date).toFormat('yyyy年M月d日') : '';
    },
    formatRecordedTime(time: string) {
      return time ? DateTime.fromISO(time).toFormat('H時m分') : '';
    },
    formatAnalyzedAt(date: Date) {
      return DateTime.fromJSDate(date).toFormat('yyyy年M月d日 H時m分');
    },
    isCheckCommentary(kind: string) {
      return ['check', 'prefix_check', 'suffix_check'].indexOf(kind) > -1;
    },
    isDislayComment(comment: Commentaries) {
      const history = this.commentaryAnalysisHistories[0];
      const index = history.imageDisplayConditions.findIndex(
        (image: ImageDisplayCondition) =>
          image.time === comment.time && image.kind == comment.kind
      );
      if (index === -1 && comment.commented) return false;

      if (index > -1 && !history.imageDisplayConditions[index].isShow)
        return false;

      return true;
    },
    printPDF() {
      const app_wrap = document.querySelector<HTMLElement>(
        '.v-application--wrap'
      );
      if (app_wrap) app_wrap.style.display = 'none';

      const report_dialog = document.querySelector<HTMLElement>(
        '.report-dialog'
      );
      if (report_dialog && report_dialog.parentElement) {
        report_dialog.parentElement.style.display = 'none';
        report_dialog.style.display = 'none';
      }

      const dialog_print = document.querySelector<HTMLElement>('.dialog-print');
      if (dialog_print && dialog_print.parentElement)
        dialog_print.parentElement.style.position = 'relative';

      window.print();
    },
  },
  mounted() {
    window.addEventListener('keydown', function(e) {
      if (e.ctrlKey && e.keyCode == 80) {
        const app_wrap = document.querySelector<HTMLElement>(
          '.v-application--wrap'
        );
        if (app_wrap) app_wrap.style.display = 'none';

        const report_dialog = document.querySelector<HTMLElement>(
          '.report-dialog'
        );
        if (report_dialog && report_dialog.parentElement) {
          report_dialog.parentElement.style.display = 'none';
          report_dialog.style.display = 'none';
        }

        const dialog_print = document.querySelector<HTMLElement>(
          '.dialog-print'
        );
        if (dialog_print && dialog_print.parentElement)
          dialog_print.parentElement.style.position = 'relative';
      }
    });
    window.addEventListener('afterprint', () => {
      const app_wrap = document.querySelector<HTMLElement>(
        '.v-application--wrap'
      );
      if (app_wrap) app_wrap.style.display = 'flex';

      const report_dialog = document.querySelector<HTMLElement>(
        '.report-dialog'
      );
      if (report_dialog && report_dialog.parentElement) {
        report_dialog.parentElement.style.display = 'flex';
        report_dialog.style.display = 'block';
      }

      const dialog_print = document.querySelector<HTMLElement>('.dialog-print');
      if (dialog_print && dialog_print.parentElement)
        dialog_print.parentElement.style.position = 'fixed';
    });
  },
});
