
import { Component, Mixins } from 'vue-property-decorator';
import { Radar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

@Component({
  extends: Radar,
})
export default class LineChart extends Mixins(reactiveProp, Radar) {
  mounted() {
    const radarChartOptions = {
      responsive: true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              display: false,
              min: 0,
              max: 100,
              stepSize: 20,
              callback: function(val: any) {
                switch (val) {
                  case 0:
                    return '0%';
                  case 20:
                    return '20%';
                  case 40:
                    return '40%';
                  case 60:
                    return '60%';
                  case 80:
                    return '80%';
                  case 100:
                    return '100%';
                }
              },
            },
            gridLines: {
              display: false,
            },
            scaleLabel: {
              display: true,
            },
          },
        ],
      },
    };
    this.renderChart(this.chartData, radarChartOptions);
  }
}
