














































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import CommentaryResultImages from '@/components/commentaryAnalysis/CommentaryResultImages.vue';

export default Vue.extend({
  components: {
    CommentaryResultImages,
  },
  props: {
    pid: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    kindColorMap: {
      car: 'lime',
      person: 'green',
      bicycle: 'yellow',
      motorcycle: 'yellow',
      truck: 'pink',
      bus: 'light-blue',
      traffic_light: 'lime darken-3',
      check: 'blue lighten-3',
      prefix_check: 'blue lighten-3',
      suffix_check: 'blue lighten-3',
    },
    kindLabelMap: {
      car: '車',
      person: '歩行者',
      bicycle: '自転車',
      motorcycle: '二輪車',
      truck: 'トラック',
      bus: 'バス',
      traffic_light: '信号機',
      check: '呼称',
      prefix_check: '呼称 前',
      suffix_check: '呼称 後',
    },
  }),
  computed: {
    ...mapGetters('CommentaryAnalysisModule', [
      'commentaryAnalysisResult',
      'commentaryAnalysisResultAggregate',
    ]),
    ...mapGetters('CommentaryAnalysisHistoriesModule', [
      'commentaryAnalysisHistories',
    ]),
  },
  methods: {
    ...mapActions('CommentaryAnalysisHistoriesModule', [
      'updateCommentaryAnalysisHistory',
    ]),
    formatAnalyzedAt(date: Date) {
      return DateTime.fromJSDate(date).toFormat('yyyy年M月d日 H時m分');
    },
    formatRecordedDate(date: string) {
      return date ? DateTime.fromISO(date).toFormat('yyyy年M月d日') : '';
    },
    formatRecordedTime(time: string) {
      return time ? DateTime.fromISO(time).toFormat('H時m分') : '';
    },
    isCheckCommentary(kind: string) {
      return ['check', 'prefix_check', 'suffix_check'].indexOf(kind) > -1;
    },
    updateHistory(history: any) {
      this.updateCommentaryAnalysisHistory(history);
    },
  },
});
